import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import ru from "vuetify/es5/locale/ru";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#3C77BA",
        secondary: "#FFFFFF",
        error: "#E64646",
        success: "#22B763",
        grey: "#CED4DA",
        blueGrey: "#B7BEC1",
        mainBlack: "#171717",
        brandPrimary: "#3466E7",
        errorRed: "#E71D36",
        primaryDefault: "#3D3DD8",
        successGreen: "#3C8505",
      },
    },
  },
  lang: {
    locales: {
      ru,
    },
    current: "ru",
  },
  icons: {
    values: {
      alertCircle: {
        component: () => import("@/components/icons/AlertCircle"),
      },
      checkboxOff: {
        component: () => import("@/components/icons/CheckboxOff"),
      },
      checkboxOn: {
        component: () => import("@/components/icons/CheckboxOn"),
      },
      minusBox: {
        component: () => import("@/components/icons/MinusBox"),
      },
      checkCircle: {
        component: () => import("@/components/icons/CheckCircle"),
      },
      checkMail: {
        component: () => import("@/components/icons/CheckMail"),
      },
      equipLogo: {
        component: () => import("@/components/icons/EquipLogo"),
      },
      equipLogoWhite: {
        component: () => import("@/components/icons/EquipLogoWhite"),
      },
      eyeOff: {
        component: () => import("@/components/icons/EyeOff"),
      },
      eyeOn: {
        component: () => import("@/components/icons/EyeOn"),
      },
      radioOff: {
        component: () => import("@/components/icons/RadioOff"),
      },
      radioOn: {
        component: () => import("@/components/icons/RadioOn"),
      },
      radioError: {
        component: () => import("@/components/icons/RadioError"),
      },
      radioDisabled: {
        component: () => import("@/components/icons/RadioDisabled"),
      },
      unfoldMoreHorizontal: {
        component: () => import("@/components/icons/UnfoldMoreHorizontal"),
      },
      homeOutline: {
        component: () => import("@/components/icons/HomeOutline"),
      },
      customRight: {
        component: () => import("@/components/icons/CustomRight"),
      },
      pencilOutline: {
        component: () => import("@/components/icons/PencilOutline"),
      },
      pencilOutline2: {
        component: () => import("@/components/icons/PencilOutline2"),
      },
      pencilOutline3: {
        component: () => import("@/components/icons/PencilOutline3"),
      },
      fastenBlue: {
        component: () => import("@/components/icons/FastenBlue"),
      },
      unFastenBlue: {
        component: () => import("@/components/icons/UnFastenBlue"),
      },
      watchNoon: {
        component: () => import("@/components/icons/WatchNoon"),
      },
      closeCircleOutlineBlue: {
        component: () => import("@/components/icons/CloseCircleOutlineBlue"),
      },
      closeIcon: {
        component: () => import("@/components/icons/CloseIcon"),
      },
      checkSuccess: {
        component: () => import("@/components/icons/CheckSuccess"),
      },
      checkCancel: {
        component: () => import("@/components/icons/CheckCancel"),
      },
      truckOutline: {
        component: () => import("@/components/icons/TruckOutline"),
      },
      completeActive: {
        component: () => import("@/components/icons/CompleteActive"),
      },
      completeDisabled: {
        component: () => import("@/components/icons/CompleteDisabled"),
      },
      fileCheck: {
        component: () => import("@/components/icons/FileCheck"),
      },
      checkCircleOutline: {
        component: () => import("@/components/icons/CheckCircleOutline"),
      },
      fileOutline: {
        component: () => import("@/components/icons/FileOutline"),
      },
      fileList: {
        component: () => import("@/components/icons/FileList"),
      },
      mapMarkerOutline: {
        component: () => import("@/components/icons/MapMarkerOutline"),
      },
      equipmentOutline: {
        component: () => import("@/components/icons/EquipmentOutline"),
      },
      listIcon: {
        component: () => import("@/components/icons/ListIcon"),
      },
      calendarOutline: {
        component: () => import("@/components/icons/CalendarOutline"),
      },
      menuListIcon: {
        component: () => import("@/components/icons/MenuListIcon"),
      },
      menuBasketOutline: {
        component: () => import("@/components/icons/MenuBasketOutline"),
      },
      menuNotebook: {
        component: () => import("@/components/icons/MenuNotebook"),
      },
      arrowLeft: {
        component: () => import("@/components/icons/ArrowLeft"),
      },
      eyeOutline: {
        component: () => import("@/components/icons/EyeOutline"),
      },
      wrenchOutline: {
        component: () => import("@/components/icons/WrenchOutline"),
      },
      bagOutline: {
        component: () => import("@/components/icons/BagOutline"),
      },
      trashOutline: {
        component: () => import("@/components/icons/TrashOutline"),
      },
      archiveOutline: {
        component: () => import("@/components/icons/ArchiveOutline"),
      },
      checkIcon: {
        component: () => import("@/components/icons/CheckIcon"),
      },
      plusIcon: {
        component: () => import("@/components/icons/PlusIcon"),
      },
      chevronLeft: {
        component: () => import("@/components/icons/ChevronLeft"),
      },
      chevronRight: {
        component: () => import("@/components/icons/ChevronRight"),
      },
      chevronDown: {
        component: () => import("@/components/icons/ChevronDown"),
      },
      chevronUp: {
        component: () => import("@/components/icons/ChevronUp"),
      },
      magnifyIcon: {
        component: () => import("@/components/icons/MagnifyIcon"),
      },
      upIcon: {
        component: () => import("@/components/icons/UpIcon"),
      },
      cogIcon: {
        component: () => import("@/components/icons/CogIcon"),
      },
      cashIcon: {
        component: () => import("@/components/icons/CashIcon"),
      },
      carOutline: {
        component: () => import("@/components/icons/CarOutline"),
      },
    },
  },
});
